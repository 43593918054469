import * as React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const DividorTitle = ({ title }: { title: string }) => {
  const titleRef = React.useRef(null);
  const [w, setW] = React.useState(20);

  React.useEffect(() => {
    if (titleRef.current) {
      const w = titleRef.current.offsetWidth;
      setW(w);
    }
  }, [titleRef]);

  return (
    <div className="flex flex-row items-center gap-8">
      <hr
        className="border-asbrown-100"
        style={{
          width: `calc((80vw - 4rem - ${w}px) / 2)`,
        }}
      />
      <div
        className="text-asbrown-light text-2xl whitespace-nowrap"
        ref={titleRef}
      >
        {title}
      </div>
      <hr
        className="border-asbrown-100"
        style={{
          width: `calc((80vw - 4rem - ${w}px) / 2)`,
        }}
      />
    </div>
  );
};

const TeamPage = () => {
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      className="flex flex-col min-h-[calc(100vh+4rem)]"
    >
      <NavBar bigLogo={false} />
      <Helmet>
        <title>Team – Anysphere</title>
      </Helmet>
      <div className="w-full grid justify-items-center gap-10 pt-20 px-6 pb-20">
        <DividorTitle title="Team" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-20">
          <div className="max-w-sm border px-4 py-4 rounded-3xl hover:shadow-md shadow-sm bg-white">
            <div className="w-32 h-32 mx-auto mb-3">
              <StaticImage
                src="../images/sualeh-asif.jpeg"
                alt="Photo of Sualeh looking into the camera, smiling."
                className="rounded-full"
                loading="eager"
                placeholder="blurred"
              ></StaticImage>
            </div>
            <div className="font-bold text-asbrown-dark mx-auto text-center text-xl">
              Sualeh Asif
            </div>
            <div className="text-asbrown-dark mt-2 text-center">
              <a href="mailto:sualeh@anysphere.co" className="hover:underline">
                sualeh@anysphere.co
              </a>
            </div>
          </div>
          <div className="max-w-sm border px-4 py-4 rounded-3xl hover:shadow-md shadow-sm bg-white">
            <div className="w-32 h-32 mx-auto mb-3">
              <StaticImage
                src="../images/shengtong-zhang.jpeg"
                alt="Photo of Shengtong, smiling."
                loading="eager"
                className="rounded-full"
                placeholder="blurred"
              ></StaticImage>
            </div>
            <div className="font-bold text-asbrown-dark mx-auto text-center text-xl">
              Shengtong Zhang
            </div>
            <div className="text-asbrown-dark mt-2 text-center">
              <a
                href="mailto:stzh1555@anysphere.co"
                className="hover:underline"
              >
                stzh1555@anysphere.co
              </a>
            </div>
          </div>
          <div className="max-w-sm border px-8 py-4 rounded-3xl hover:shadow-md shadow-sm bg-white">
            <div className="w-32 h-32 mx-auto mb-3">
              <StaticImage
                src="../images/arvid-lunnemark.jpeg"
                alt="Photo of Arvid with a cup of coffee, smiling."
                loading="eager"
                className="rounded-full"
                placeholder="blurred"
              ></StaticImage>
            </div>
            <div className="font-bold text-asbrown-dark mx-auto text-center text-xl">
              Arvid Lunnemark
            </div>
            <div className="text-asbrown-dark mt-2 text-center">
              <a href="mailto:arvid@anysphere.co" className="hover:underline">
                arvid@anysphere.co
              </a>
            </div>
          </div>
        </div>
        <div className="text-asbrown-light pt-20 pb-10 text-lg">
          Contact us at{" "}
          <a className="hover:underline" href="mailto:hello@anysphere.co">
            hello@anysphere.co
          </a>
          .
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default TeamPage;
